.about-video-section {
  margin-top: 3rem;
  padding: 2rem 0;
  border-top: 1px solid var(--border-color);
}

.about-video-section h2 {
  color: var(--primary-color);
  margin-bottom: 3rem;
  position: relative;
  padding-bottom: 1rem;
  text-align: center;
}

.about-video-section h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background-color: var(--primary-color);
}

/* Ensure consistent spacing from underline to content */
.video-slider {
  margin-top: 2rem;
}

/* Add this to target the lead paragraph after the main section title */
.section-title + .row .lead:first-of-type {
  margin-top: 2rem;
}

.section-padding {
  padding: 5rem 0;
}

@media (max-width: 768px) {
  .about-video-section {
    margin-top: 2rem;
    padding: 1.5rem 0;
  }
  
  .about-video-section h2 {
    margin-bottom: 2.5rem;
  }
  
  .video-slider {
    margin-top: 2.5rem;
  }
  
  .section-title + .row .lead:first-of-type {
    margin-top: 1.5rem;
  }
  
  .section-padding {
    padding: 3rem 0;
  }
} 