:root {
  /* Light theme (default) */
  --primary-color: #8b5a2b;
  --secondary-color: #d4a76a;
  --text-color: #333;
  --background-color: #fff;
  --card-bg-color: #f8f9fa;
  --border-color: #dee2e6;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --navbar-bg: #fff;
  --navbar-text: #333;
  --hero-overlay: rgba(0, 0, 0, 0.5);
  --footer-bg: #f8f9fa;
  --footer-text: #333;
  
  /* Font settings */
  --font-family: 'Quicksand', sans-serif;
  --heading-font: 'Quicksand', sans-serif;
  --base-font-size: 16px;
  --heading-font-weight: 700;
  --body-font-weight: 400;
}

/* Dark theme */
[data-theme='dark'] {
  --primary-color: #d4a76a;
  --secondary-color: #8b5a2b;
  --text-color: #f0f0f0;
  --background-color: #121212;
  --card-bg-color: #1e1e1e;
  --border-color: #444;
  --shadow-color: rgba(255, 255, 255, 0.05);
  --navbar-bg: #1e1e1e;
  --navbar-text: #f0f0f0;
  --hero-overlay: rgba(0, 0, 0, 0.7);
  --footer-bg: #1e1e1e;
  --footer-text: #f0f0f0;
}

/* Base styles */
body {
  margin: 0;
  font-family: var(--font-family);
  font-size: var(--base-font-size);
  font-weight: var(--body-font-weight);
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Apply transition to all elements for smooth theme switching */
* {
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--heading-font);
  font-weight: var(--heading-font-weight);
  margin-bottom: 1rem;
  color: var(--text-color);
}

p {
  margin-bottom: 1rem;
  color: var(--text-color);
}

a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: var(--secondary-color);
}

/* Section padding */
section {
  padding: 4rem 0;
}

@media (max-width: 768px) {
  section {
    padding: 2rem 0;
  }
}

/* Card styling */
.card {
  background-color: var(--card-bg-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  box-shadow: 0 4px 6px var(--shadow-color);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px var(--shadow-color);
}

/* Button styling */
.btn {
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: white !important;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}

.btn-outline-primary {
  color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  background-color: transparent !important;
}

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active {
  background-color: var(--primary-color) !important;
  color: white !important;
}

/* Container adjustments */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}
