.blog-post-editor {
  padding: 2rem 0;
  max-width: 1200px;
  margin: 0 auto;
}

.editor-title {
  margin-bottom: 2rem;
  color: var(--primary-color);
  font-size: 2.5rem;
  text-align: center;
}

.editor-header {
  background: var(--card-bg);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: var(--card-shadow);
  margin-bottom: 2rem;
}

.form-label {
  color: var(--text-color);
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.form-control {
  background: var(--bg-color);
  border: 1px solid var(--border-color);
  color: var(--text-color);
  padding: 0.75rem;
  border-radius: 4px;
}

.form-control:focus {
  background: var(--bg-color);
  border-color: var(--primary-color);
  color: var(--text-color);
  box-shadow: 0 0 0 0.2rem rgba(var(--primary-rgb), 0.25);
}

.image-preview {
  margin-top: 1rem;
  max-width: 300px;
  border-radius: 4px;
  overflow: hidden;
}

.image-preview img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.publish-button {
  width: 100%;
  padding: 1rem;
  font-size: 1.1rem;
  margin-top: 1rem;
  background: var(--primary-color);
  border: none;
  transition: all 0.3s ease;
}

.publish-button:hover {
  background: var(--primary-dark);
  transform: translateY(-2px);
}

.preview-section {
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid var(--border-color);
}

.preview-section h2 {
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  text-align: center;
}

.blog-post {
  background: var(--card-bg);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: var(--card-shadow);
}

.blog-post-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.blog-post-content {
  padding: 2rem;
}

.blog-post-category {
  display: inline-block;
  background: var(--primary-color);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.blog-post-title {
  font-size: 2rem;
  color: var(--text-color);
  margin-bottom: 1rem;
}

.blog-post-meta {
  display: flex;
  gap: 1.5rem;
  color: var(--text-muted);
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
}

.blog-post-meta svg {
  margin-right: 0.5rem;
}

.blog-post-quote {
  border-left: 4px solid var(--primary-color);
  padding-left: 1.5rem;
  margin: 2rem 0;
  font-style: italic;
  color: var(--text-color);
  font-size: 1.2rem;
}

.blog-post-content p {
  margin-bottom: 1.5rem;
  line-height: 1.8;
  color: var(--text-color);
}

.tag-cloud {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 2rem;
}

.tag {
  display: inline-flex;
  align-items: center;
  background: var(--bg-color);
  color: var(--text-color);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  text-decoration: none;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.tag:hover {
  background: var(--primary-color);
  color: white;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .blog-post-editor {
    padding: 1rem;
  }

  .editor-header {
    padding: 1rem;
  }

  .blog-post-image {
    height: 300px;
  }

  .blog-post-content {
    padding: 1.5rem;
  }

  .blog-post-title {
    font-size: 1.5rem;
  }
} 