/* StayConnected.css */

/* Header Section */
.connect-header {
  margin-bottom: 2rem;
}

.connect-header h2 {
  color: var(--primary-color);
  font-size: 2.5rem;
  position: relative;
  display: inline-block;
  padding-bottom: 0.5rem;
}

.connect-header h2:after {
  content: '';
  position: absolute;
  width: 50%;
  height: 3px;
  background-color: var(--primary-color);
  bottom: 0;
  left: 25%;
}

/* Form Container */
.connect-form-container {
  background-color: var(--card-bg-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  box-shadow: 0 4px 12px var(--shadow-color);
  padding: 2rem;
  margin-bottom: 3rem;
  transition: all 0.3s ease;
}

.connect-form-container h3 {
  color: var(--primary-color);
  margin-bottom: 1.5rem;
}

/* Connect Options */
.connect-options {
  margin: 1.5rem 0;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 1rem;
  background-color: rgba(var(--primary-rgb), 0.05);
}

.connect-option {
  margin-bottom: 1rem;
}

.connect-option .form-check-label {
  font-weight: 500;
  padding-left: 0.5rem;
}

.connect-option .form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.option-label {
  display: flex;
  align-items: center;
}

.option-icon {
  color: var(--primary-color);
  margin-right: 0.5rem;
  font-size: 1.1rem;
  width: 1.2rem;
  transition: transform 0.2s ease;
}

.connect-option:hover .option-icon {
  transform: scale(1.2);
}

/* Social Media Icons */
.social-icons-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.social-icon {
  font-size: 2.5rem;
  color: var(--primary-color);
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-icon:hover {
  transform: translateY(-5px);
  color: var(--secondary-color);
}

/* Messages */
.error-message {
  color: #dc3545;
  background-color: rgba(220, 53, 69, 0.1);
  padding: 0.75rem;
  border-radius: 4px;
  margin-top: 1rem;
  display: none;
}

.error-message.show {
  display: block;
}

.success-message {
  color: #198754;
  background-color: rgba(25, 135, 84, 0.1);
  padding: 0.75rem;
  border-radius: 4px;
  margin-top: 1rem;
  display: none;
}

.success-message.show {
  display: block;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .social-icons-container {
    gap: 1.5rem;
  }
  
  .connect-header h2 {
    font-size: 2rem;
  }
  
  .social-icon {
    font-size: 2rem;
  }
  
  .connect-form-container {
    padding: 1.5rem;
  }
} 