/* Header.css - Custom styling for the navigation header */

.custom-navbar {
  background-color: var(--navbar-bg);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  transition: all 0.3s ease;
}

/* Add style for navbar toggler icon */
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23333' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  filter: invert(0.9);
}

[data-theme='dark'] .navbar-toggler-icon {
  filter: invert(0.1);
}

.brand-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.brand-text {
  font-family: 'Playfair Display', serif;
  font-size: 1.2rem;
  line-height: 1.2;
  color: var(--navbar-text);
  text-align: right;
}

.brand-text div {
  margin: 0;
  padding: 0;
}

.brand-logo {
  display: flex;
  align-items: center;
}

.brand-logo-img {
  height: 67px;
  width: auto;
  transition: transform 0.3s ease;
}

.brand-logo-img:hover {
  transform: scale(1.05);
}

.nav-menu {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.nav-item {
  color: var(--navbar-text) !important;
  font-weight: 500;
  padding: 8px 12px !important;
  border-radius: 4px;
  transition: all 0.3s ease;
  position: relative;
  white-space: nowrap;
  font-size: 0.95rem;
}

.nav-item:hover {
  color: var(--primary-color) !important;
  background-color: rgba(0, 0, 0, 0.05);
}

.nav-item.active {
  color: var(--primary-color) !important;
  font-weight: 600;
}

.nav-item.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 15%;
  width: 70%;
  height: 2px;
  background-color: var(--primary-color);
  transition: width 0.3s ease;
}

.theme-toggle-container {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.nav-right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

/* Responsive adjustments */
@media (max-width: 991px) {
  .nav-menu {
    gap: 5px;
    margin-top: 15px;
    align-items: flex-start;
  }
  
  .nav-item {
    padding: 10px !important;
  }
  
  .theme-toggle-container {
    position: relative;
    right: auto;
    top: auto;
    transform: rotate(-90deg);
    margin-top: 15px;
    margin-left: 12px;
  }
} 