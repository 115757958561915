/* ZoomCommunity.css */

.zoom-info-box {
  background-color: var(--card-bg-color);
  border: 1px solid var(--border-color);
  box-shadow: 0 4px 8px var(--shadow-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

/* Style for the icon in the meeting cards */
.zoom-info-box .fa-icon {
  color: var(--primary-color);
}

/* Ensure text in cards is properly colored */
.card .text-muted {
  color: var(--text-color) !important;
  opacity: 0.7;
}

/* Additional responsive styles */
@media (max-width: 768px) {
  .zoom-info-box {
    padding: 1.5rem !important;
  }
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px var(--shadow-color);
  background-color: var(--card-bg-color);
  margin-bottom: 2rem;
}

.video-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* Call to Action styles */
.cta-container {
  padding: 2rem;
  background-color: var(--card-bg-color);
  border-radius: 8px;
  box-shadow: 0 4px 8px var(--shadow-color);
}

.cta-container h3 {
  color: var(--text-color);
  font-weight: 600;
}

.cta-button {
  padding: 0.75rem 2rem;
  font-weight: 500;
  transition: transform 0.2s ease-in-out;
}

.cta-button:hover {
  transform: translateY(-2px);
}

/* Ensure consistent spacing */
.section-padding {
  padding: 5rem 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .video-container {
    margin-bottom: 1.5rem;
  }
  
  .section-padding {
    padding: 3rem 0;
  }

  .cta-container {
    padding: 1.5rem;
  }

  .cta-container h3 {
    font-size: 1.5rem;
  }
} 