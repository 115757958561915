/* App.css - Main styling for the Prophetic Roundtable website */

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

/* Header styles moved to Header.css */

/* Hero Section */
.hero {
  position: relative;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  overflow: hidden;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--hero-overlay);
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 2;
  max-width: 800px;
  padding: 0 20px;
}

.ministry-title {
  margin-bottom: 1.5rem;
}

.hero-title {
  font-size: 3.5rem;
  margin-bottom: 0.2rem;
  color: white;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.ministry-subtitle {
  font-size: 22px;
  font-family: var(--heading-font);
  color: white;
  font-weight: 600;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.hero-text {
  font-size: 2.5rem;
  margin-bottom: 2.5rem;
  font-weight: 400;
  margin-top: 1.5rem;
  font-family: 'Mr Dafoe', cursive;
  letter-spacing: 2px;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  color: #fff;
  transform: rotate(-2deg);
}

.hero-btn {
  padding: 12px 30px;
  font-size: 1.1rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.hero-btn:hover {
  background-color: var(--secondary-color);
  transform: translateY(-3px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

/* Section Padding */
.section-padding {
  padding: 5rem 0;
  background-color: var(--background-color);
  color: var(--text-color);
}

/* Mission Section */
.mission-section {
  padding: 5rem 0;
  background-color: var(--card-bg-color);
  color: var(--text-color);
}

.mission-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.mission-content h2 {
  color: var(--primary-color);
  margin-bottom: 2rem;
  font-size: 2.5rem;
}

.mission-content p {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

/* Video Container Styles */
.video-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 2rem auto;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 15px var(--shadow-color);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.video-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px var(--shadow-color);
}

.responsive-video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

.responsive-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  background-color: var(--card-bg-color);
}

/* About Section */
.about-section {
  padding: 5rem 0;
  background-color: var(--card-bg-color);
  color: var(--text-color);
}

.about-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.about-text {
  flex: 1;
  min-width: 300px;
  padding: 0 20px;
}

.about-image {
  flex: 1;
  min-width: 300px;
  padding: 0 20px;
}

.about-image img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 5px 15px var(--shadow-color);
}

.about-text h2 {
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  font-size: 2.5rem;
}

.about-text p {
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
}

/* Section Styles */
.section-title {
  position: relative;
  margin-bottom: 3rem;
  padding-bottom: 1rem;
  text-align: center;
  color: var(--primary-color);
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background-color: var(--primary-color);
}

/* Card Styles */
.card {
  border: none;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px var(--shadow-color);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 2rem;
  background-color: var(--card-bg-color);
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px var(--shadow-color);
}

.card-img-top {
  height: 200px;
  object-fit: cover;
}

.card-title {
  color: var(--primary-color);
  font-weight: 600;
}

.card-category {
  color: var(--secondary-color);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

/* Event Styles */
.event-card {
  background-color: var(--card-bg-color);
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 8px var(--shadow-color);
  margin-bottom: 2rem;
  text-align: center;
}

.event-date {
  font-size: 1.2rem;
  color: var(--secondary-color);
  margin-top: 1rem;
}

/* Footer Styles - Now using variables from Footer.css */

/* Responsive Adjustments */
@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }
  
  .ministry-subtitle {
    font-size: 18px;
  }
  
  .hero-text {
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  
  .about-content {
    flex-direction: column;
  }
  
  .about-text, .about-image {
    width: 100%;
    margin-bottom: 2rem;
  }
}

@media (max-width: 576px) {
  .hero-title {
    font-size: 2rem;
  }
  
  .ministry-subtitle {
    font-size: 16px;
  }
  
  .hero-text {
    font-size: 1.8rem;
  }
}
