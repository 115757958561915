.video-slider {
  margin: 2rem 0;
  padding: 1rem;
  background-color: var(--card-bg-color);
  border-radius: 8px;
  box-shadow: 0 4px 8px var(--shadow-color);
}

.video-carousel {
  margin-bottom: 1.5rem;
  border-radius: 8px;
  overflow: hidden;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.carousel-caption {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  padding: 10px;
  bottom: 20px;
}

.carousel-indicators {
  bottom: -10px;
}

.carousel-indicators button {
  background-color: var(--primary-color);
  opacity: 0.5;
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-control-prev,
.carousel-control-next {
  width: 5%;
  opacity: 0.8;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: var(--primary-color);
  border-radius: 50%;
  padding: 10px;
}

.video-navigation {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 1rem;
}

.video-nav-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.video-nav-button:hover {
  transform: translateY(-3px);
}

/* Dark mode adjustments */
[data-theme='dark'] .carousel-caption {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .video-container {
    padding-bottom: 65%; /* Slightly taller on mobile */
  }
  
  .carousel-caption {
    padding: 5px;
    bottom: 10px;
  }
  
  .carousel-caption h3 {
    font-size: 1rem;
  }
  
  .video-nav-button {
    width: 35px;
    height: 35px;
    font-size: 0.9rem;
  }
} 