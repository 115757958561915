.dark-mode-toggle {
  display: flex;
  align-items: center;
  margin-left: 15px;
  position: relative;
}

.dark-mode-input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.dark-mode-label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 30px;
  height: 60px;
  background: linear-gradient(145deg, #f0f0f0, #e6e6e6);
  border-radius: 30px;
  transition: all 0.4s ease;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1), 
              0 -1px 3px rgba(255, 255, 255, 0.8),
              inset 0 0 5px rgba(0, 0, 0, 0.05);
  padding: 5px;
  overflow: hidden;
  flex-direction: column;
}

/* Dark mode styling */
[data-theme='dark'] .dark-mode-label {
  background: linear-gradient(145deg, #2a2a2a, #222222);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3), 
              0 -1px 3px rgba(60, 60, 60, 0.2),
              inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.dark-mode-slider {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: linear-gradient(145deg, #ffffff, #f0f0f0);
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15), 
              0 -1px 3px rgba(255, 255, 255, 0.8);
  z-index: 2;
}

.dark-mode-input:checked + .dark-mode-label .dark-mode-slider {
  top: calc(100% - 27px);
  background: linear-gradient(145deg, #444444, #333333);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3), 
              -2px -2px 5px rgba(60, 60, 60, 0.2);
}

.dark-mode-icon {
  position: absolute;
  transition: all 0.3s ease;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark-mode-icon-sun {
  top: 8px;
  opacity: 0;
}

.dark-mode-icon-moon {
  bottom: 8px;
  opacity: 1;
}

/* Reverse the icon visibility for dark mode */
.dark-mode-input:checked + .dark-mode-label .dark-mode-icon-sun {
  opacity: 1;
}

.dark-mode-input:checked + .dark-mode-label .dark-mode-icon-moon {
  opacity: 0;
}

.dark-mode-text {
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--primary-color);
  color: white;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: none;
  white-space: nowrap;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transform-origin: left center;
  transform: translateY(-50%) scale(0.9);
}

.dark-mode-label:hover .dark-mode-text {
  opacity: 1;
  transform: translateY(-50%) scale(1);
}

/* Active/pressed effect */
.dark-mode-label:active .dark-mode-slider {
  transform: scale(0.9);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2), 
              -1px -1px 3px rgba(255, 255, 255, 0.7);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .dark-mode-toggle {
    margin: 10px 0;
  }
  
  .dark-mode-label {
    width: 26px;
    height: 52px;
  }
  
  .dark-mode-slider {
    width: 20px;
    height: 20px;
  }
  
  .dark-mode-input:checked + .dark-mode-label .dark-mode-slider {
    top: calc(100% - 23px);
  }
} 