.login-container {
  min-height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.login-form {
  background: var(--card-bg);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: var(--card-shadow);
  width: 100%;
  max-width: 400px;
}

.login-form h1 {
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 2rem;
}

.form-label {
  color: var(--text-color);
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.form-control {
  background: var(--bg-color);
  border: 1px solid var(--border-color);
  color: var(--text-color);
  padding: 0.75rem;
  border-radius: 4px;
}

.form-control:focus {
  background: var(--bg-color);
  border-color: var(--primary-color);
  color: var(--text-color);
  box-shadow: 0 0 0 0.2rem rgba(var(--primary-rgb), 0.25);
}

.btn-primary {
  background: var(--primary-color);
  border: none;
  padding: 0.75rem;
  font-size: 1.1rem;
  transition: all 0.3s ease;
}

.btn-primary:hover {
  background: var(--primary-dark);
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .login-container {
    padding: 1rem;
  }

  .login-form {
    padding: 1.5rem;
  }
} 