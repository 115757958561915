/* Contact.css */

.section-padding {
  padding: 5rem 0;
}

.section-title {
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 2rem;
}

.lead {
  color: var(--text-color);
  text-align: center;
}

.form-label {
  color: var(--text-color);
  font-weight: 500;
}

.form-control {
  background-color: var(--input-bg);
  border: 1px solid var(--border-color);
  color: var(--text-color);
}

.form-control:focus {
  background-color: var(--input-bg);
  border-color: var(--primary-color);
  color: var(--text-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--primary-rgb), 0.25);
}

.form-control::placeholder {
  color: var(--text-color);
  opacity: 0.7;
}

textarea.form-control {
  min-height: 120px;
  resize: vertical;
}

/* Messages */
.error-message {
  color: #dc3545;
  background-color: rgba(220, 53, 69, 0.1);
  padding: 0.75rem;
  border-radius: 4px;
  margin-top: 1rem;
  display: none;
}

.error-message.show {
  display: block;
}

.success-message {
  color: #198754;
  background-color: rgba(25, 135, 84, 0.1);
  padding: 0.75rem;
  border-radius: 4px;
  margin-top: 1rem;
  display: none;
}

.success-message.show {
  display: block;
}

@media (max-width: 768px) {
  .section-padding {
    padding: 3rem 0;
  }
  
  .section-title {
    font-size: 2rem;
  }
} 