.container {
    width: 100%;
    min-height: calc(100vh - 160px); /* Adjust based on your header/footer height */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
}

.imageContainer {
    width: 90%;
    max-width: 1920px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image {
    max-width: 100%;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
} 