.resources-page {
  padding: 4rem 0;
  background-color: #f8f9fa;
}

.resources-page h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #333;
}

.resources-page h2 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #444;
}

.resources-page .lead {
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 2rem;
}

.subtitle-text {
  color: var(--text-muted-color);
  font-size: 1.1rem;
  margin-top: 0.5rem;
  transition: color 0.3s ease;
}

.book-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  margin-top: 2rem;
}

.book-gallery-small {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 1rem;
}

.book-card {
  background-color: var(--card-bg-color);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px var(--shadow-color);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  max-width: 250px;
}

.book-card-small {
  background-color: var(--card-bg-color);
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 3px 6px var(--shadow-color);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
}

.book-card:hover, .book-card-small:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px var(--shadow-color);
}

.book-card a, .book-card-small a {
  text-decoration: none;
  color: var(--text-color);
}

.book-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  display: block;
}

.book-card-small .book-image {
  height: 240px;
}

.book-title {
  padding: 1rem;
  text-align: center;
  font-weight: 500;
  color: var(--text-color);
  background-color: var(--card-bg-color);
}

.book-card-small .book-title {
  padding: 0.8rem;
  font-size: 0.9rem;
  min-height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .resources-page {
    padding: 2rem 0;
  }

  .resources-page h1 {
    font-size: 2rem;
  }

  .resources-page h2 {
    font-size: 1.75rem;
  }

  .book-gallery {
    gap: 1rem;
  }
  
  .book-card {
    max-width: 200px;
  }
  
  .book-image {
    height: 250px;
  }

  .book-card-small {
    max-width: 160px;
  }

  .book-card-small .book-image {
    height: 200px;
  }
} 